import $ from 'jquery';

export function Accordion() {
    $(function () {
        const Btn = $('.AccordionBox_btn');
        const CloseBtn = $('.TextBtn.-close');

        Btn.stop().on('click', function() {
            let _self = $(this); //クリックしたボタン
            let content = _self.next('.AccordionBox_content'); // クリックしたボタンに対するコンテンツ
            let select =  _self.attr('aria-selected');// クリックしたボタンのariaの状態


            if(select === 'true') { // Openだった場合
                content.attr('aria-hidden', true);
                content.slideUp(200);
                _self.attr('aria-selected', false);
            } else {
                content.attr('aria-hidden', false);
                content.slideDown(200);
                _self.attr('aria-selected', true);
            }
        });

        CloseBtn.on('click', function() {
            let _self = $(this); //クリックした閉じるボタン
            let content = _self.parent('.AccordionBox_content'); //クリックした閉じるボタンの親要素
            let btn = content.prev('.AccordionBox_btn'); //クリックした閉じるボタンに対するアコーディオンボタン

            content.attr('aria-hidden', true);
            content.slideUp();
            btn.attr('aria-selected', false);
        });

    });
}