import $ from 'jquery';

export function ToggleMenu() {
    const Btn = $('.NavBtn');
    const Menu = $('.Nav');
	const breakpoint = 920

    function reset() {
        Menu.attr('aria-hidden','true');
        Menu.css('display','');
        Menu.removeClass('-opened');
        Menu.removeClass('-closed');
        Btn.removeClass('-opened');
        Btn.removeClass('-closed');
    }

    // 初期設定
    function menuReset() {
		Menu.attr('aria-hidden','true');
		Menu.css('display','');
		Menu.addClass('-closed');
		Btn.removeClass('-opened');
		Btn.addClass('-closed');
    }


    // Menuの開閉
    function MenuOpen() {
		Menu.addClass('-opened');
		Menu.removeClass('-closed');
		Menu.attr('aria-hidden','false');
		Menu.slideDown();
    }
    function MenuClose() {
    	Menu.removeClass('-opened');
    	Menu.addClass('-closed');
		Menu.attr('aria-hidden', 'true');
		Menu.slideUp();
    }

    // Button
    function changeButtonClose() {
        Btn.attr('aria-selected','false');
        Btn.removeClass('-opened');
        Btn.addClass('-closed');
    }
    function changeButtonOpen() {
        Btn.attr('aria-selected','true');
		Btn.removeClass('-closed');
		Btn.addClass('-opened');
    }


    // ButtonクリックでMenuオープン
    function toggleMenu() {
        Btn.click(function () {
            if($(this).attr('aria-selected') === 'false') {
                MenuOpen();
                changeButtonOpen();
            } else {
                MenuClose();
                changeButtonClose();
            }
        });
    }

    // ロード時とリサイズ時にリセット
    $(window).on('load', function () {
        if (breakpoint > window.innerWidth) {
            changeButtonClose();
            menuReset();
        } else {
            reset();
        }
    })
    $(window).on('resize', function () {
        if (breakpoint > window.innerWidth) {
            changeButtonClose();
            menuReset();
        } else {
            reset();
        }
    })

    $(function () {
        toggleMenu();
    });

}
