import $ from 'jquery';

export function Scroll() {
    let headerH = $('.Header').outerHeight();
    let speed = 400;
    let urlHash = location.hash;

    if(urlHash) {
        $('body,html').stop().scrollTop(0);

        setTimeout(function(){
            let target = $(urlHash);
            let position = target.offset().top - headerH;
            $('body,html').stop().animate({scrollTop:position}, speed, 'swing');
        }, 100);
    }

    $(function () {
    	console.log(headerH);
        $('a[href^="#"]').click(function () {
            let href = $(this).attr('href');
            let target = $(href === '#'  || href === '' ? 'html' : href);
            let position = target.offset().top - headerH;

            if(href !== '#top'){
                $('body,html').animate({scrollTop:position}, speed, 'swing');
            } else {
                position = target.offset().top;
                $('body,html').animate({scrollTop:position}, speed, 'swing');
            }
        });
    });
}
